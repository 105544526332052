<template>
  <div>

<!-- Header -->
    <div class="header">
      <global-Banner :pagename=pagename /> <!-- Links to src/global/Banner.vue -->
    </div>

<!-- Main body -->
    <local-about-body/> <!-- Links to src/local/home/top-statement.vue -->

  </div>
</template>



<script>

  export default {
    name: 'About',
    data() {
      return {
        pagename: 'about'
      }
    }
  }
</script>

